/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    strong: "strong",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-get-rid-of-pressure-in-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-get-rid-of-pressure-in-your-ears",
    "aria-label": "how to get rid of pressure in your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How To Get Rid Of Pressure In Your Ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hero-grey-glitter.png",
    alt: "Glitter background hero",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Does everything sound numb? Do you feel like there’s something putting pressure on the inside of your eardrum, like after taking off in a plane or diving too deep in a pool, but worse? Chances are you suffer from pressure in your ear or barotrauma."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Reading this article will teach you how to rid yourself of that pressure for good and find out what you can do when you already suffer from a permanent hearing impairment. If you are over the age of 55, you should get checked by a doctor to see if you have any permanent damage."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear fullness, ear barotrauma, stuffy ears: there are many ways to describe the sensation. Furthermore, the sensation of pressure in the ears can be linked to stuffy sinuses, a sore throat or even tinnitus. So how do you know which is afflicting you?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Many individuals who do experience ear fullness might also, eventually, lose hearing in the afflicted ear"), " – as symptoms range from mild to moderate. However, if you’re experiencing hearing loss due to ear barotrauma, rest assured, your hearing will return and this loss is only temporary and reversible. And so are your symptoms. That’s the good news. Waiting for the other shoe to drop? Well, there isn’t one. But you still should be aware of the source of discomfort, zero in on the cause, and seek medical attention, if it persists. In this article, we help you break down the causes of ear pressure so you can get through and out the other side."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-decongest-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-decongest-your-ears",
    "aria-label": "how to decongest your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How To Decongest Your Ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are there techniques you can “DIY” to seek immediate relief? Besides the more well-known and common methods of yawning or chewing gum, ear barotrauma can be “treated” by something as simple as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Breathing exercises"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Taking a decongestant"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Lying horizontally, if struck by vertigo"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Taking an antihistamine"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your ear pressure is related to a persistent cold or flu, try the following home remedies:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Keep your nasal passages hydrated and salinated, as well as unclogged with the help of a nasal spray. This will alleviate more immediate pressure from both the sinuses and the ears"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Use a humidifier in your bedroom to keep the air moist"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "For pain that may accompany the pressure, try using ibuprofen or naproxen to relieve the ache"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Just as you want to avoid extreme pressure changes, you’ll also want to avoid extreme temperature changes. Keep liquids warm to lukewarm but do not drink something extremely cold"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Obviously diagnosing ear barotrauma and ear pressure as a secondary symptom to clogged sinuses is one thing. But there may be more serious cases that require surgery. Usually these are because of a cyst that wasn’t drained in time, chronic barotrauma or severe strain to the tympanostomy tubes."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In most cases, ear pressure is not an indicator of anything more serious than a change in external environment. But, if accompanied by a persistent pain that grows in severity, the flu and other physical symptoms, it could be a sign of a more serious issue."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In this case, it’s wise to head to a medical professional for diagnosis. From here, your doctor will be able to definitively say whether a course of antibiotics is the answer, whether surgery may heal inner ear pressure due to fluid, if it’s a cyst that needs draining, or any other more serious cases."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-can-cause-pressure-in-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-cause-pressure-in-your-ears",
    "aria-label": "what can cause pressure in your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Can Cause Pressure In Your Ears?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This may seem counter-intuitive but a change in air pressure can cause pressure in your ears. ", React.createElement(_components.strong, null, "This is known as ear barotrauma"), ". The way that a barometer is meant to measure changes in atmospheric pressure, is the way the ear senses differences in the outside environment. Though its physical structure is small, the “equipment” of the inner ear is a powerful mechanism which helps keep our balance and is intricately connected to our sense of smell, touch, and the ability to feel hot or cold."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "So barotrauma is the discomfort experienced when the ear, as a whole, senses a pressure change"), ". Think about the last time you went on an airplane – as it takes off and lands, you ascend and descend, which changes the pressure in the atmosphere. As you are transitioning from the ground to the air, your inner ear tube, known as the Eustachian tube, may become blocked or sensitive to the change in outer air pressure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This also happens when diving. Once again, the body maintains equilibrium when on land. But, underwater, there is more pressure being exerted, which means pressure on the inner ear, as well. Infants and young children experience this change most acutely and frequently, but it is rarely a cause for concern."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another reason why you may be feeling uncomfortable ear pressure is because ", React.createElement(_components.strong, null, "you’re going through a cold or flu and you’re all congested"), ". The system of tubes and canals that make up the ears are intricately connected to your throat and nose (this is why a specialist treating this area would be known as an “ENT” or “ear, nose, throat” specialist – the structures of the head and neck are closely related)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your sinuses are feeling stuffy, this can cause you to feel ear pressure or experience “blocked ears” as well. Usually, this means that you have a muffled sensation in your ear or even a slight, dull throb that comes from the pressure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are other reasons why you could be feeling pressure within your ears; ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "there could be impacted earwax"), " lodged deep within that needs some gentle, professional coaxing out or you could be experiencing an infection of various parts of the ears. These include an imbalance of fluid or an accumulation of excess fluid known as hydrops, ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-media/",
    className: "c-md-a"
  }, "otitis media"), ", which is inflammation of the middle ear, tensor tympani syndrome, which is a strain on the middle ear muscles from continuously loud sounds or cholesteatoma. This is a benign cyst that forms due to chronic ear infections that, while not serious, requires immediate drainage."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "feeling-the-consequences-of-pressure-in-the-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#feeling-the-consequences-of-pressure-in-the-ears",
    "aria-label": "feeling the consequences of pressure in the ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Feeling The Consequences Of Pressure In The Ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The side effects of feeling pressure in your ears can trigger a whole host of other accompanying sensations that are rather unpleasant."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Take heart, though: ", React.createElement(_components.strong, null, "most of these will pass right away, as the pressure clears"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In cases of ear barotrauma, the discomfort you are feeling is primarily due to the direct effect on the Eustachian tube. This tube is responsible for sensing and maintaining pressure equilibrium. The pressure itself is caused by a difference between atmospheric pressure on the outside versus the inside. What our brains register as discomfort or a situation out of the balance, our inner ear is working to restore."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Altitude changes that you can control are activities such as diving or climbing and hiking. Go slowly, either while ascending or descending and give your ears time to adjust. Ear barotrauma may also increase the chance of having a minor nosebleed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re on a plane, there may not be much you can do. Try yawning or even chewing gum as these tricks may relieve the pressure immediately and cause the ears to “pop.”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear pressure brought on by clogged sinuses can bring a whole host of other sensations including: dizziness, pain, muffled hearing and, sometimes, even ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/bppv/",
    className: "c-md-a"
  }, "vertigo"), " due to inner ear imbalance. And this is especially true of inner ear tube inflammations or ear infections."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some more persistent and severe cases of ear infections such as otitis media, externa or ", React.createElement(_components.a, {
    href: "/hearing-loss/cholesteatoma/",
    className: "c-md-a"
  }, "cholesteatoma"), " can lead to meningitis. Cysts that are not treated can spread to infection in the delicate bones of the ears, or even those that protect the brain, also resulting in ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "permanent hearing loss"), ". If this is something you are currently experiencing, then should seek medical attention."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
